<template>
  <div>
    <CCard>
      <CCardHeader>
        Add Port Forward
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="4" sm="12">
            <CSelect
              placeholder="Router"
              :options="routerListItem"
              prepend="Router"
              :value.sync="router"
              @change="getRouterInterface"
            />
          </CCol>
          <CCol md="2" sm="12">
            <CSelect
              :options="[false,true]"
              prepend="Port Range"
              :value.sync="isPortRange"
            />
          </CCol>
          <CCol md="5" sm="12">
            <CSelect
              placeholder="Please select a router first"
              :options="interfaceListItem"
              prepend="Outside Interface"
              :value.sync="interface"
            />
          </CCol>
          <CCol md="1" sm="12">
            <CButton
              color="primary"
              square
              @click="syncRouterInterface"
            >
              <CIcon name="cil-sync" />
              Sync
            </CButton>
          </CCol>
          <CCol md="3" sm="12">
            <CSelect
              placeholder="Protocol"
              :options="protocolList"
              prepend="Protocol"
              :value.sync="protocol"
            ></CSelect>
          </CCol>
          <CCol md="3" sm="12">
            <CInput
              placeholder="1...65535"
              class="mb-4"
              prepend="Outside Port"
              type="number"
              min="1"
              max="65535"
              :value.sync="port"
            >
            </CInput>
          </CCol>
          <CCol md="3" sm="12" v-if="isPortRange">
            <CInput
              placeholder="1...65535"
              class="mb-4"
              prepend="PortEnd"
              type="number"
              min="1"
              max="65535"
              :value.sync="portEnd"
            >
            </CInput>
          </CCol>
          <CCol md="3" sm="12">
            <CInput
              placeholder="TargetHost"
              prepend="TargetHost"
              :value.sync="toHost"
            />
          </CCol>
          <CCol md="3" sm="12" v-if="!isPortRange">
            <CInput
              placeholder="1...65535"
              class="mb-4"
              prepend="TargetPort"
              type="number"
              min="1"
              max="65535"
              :value.sync="toPort"
            >
            </CInput>
          </CCol>
          <CCol md="12" sm="12">
            <CTextarea
              placeholder="What is this port forward for?"
              class="mb-4"
              prepend="Remark"
              :value.sync="remark"
            />
          </CCol>
          <CCol md="12" sm="12">
            <CButton
              color="primary"
              square
              @click="addPortForward"
            >
              Submit
            </CButton>
          </CCol>

        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        User Port Forward List
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              hover
              small
              fixed
              table-filter
              sorter
              items-per-page-select
              :items-per-page="15"
              :fields="portForwardListFields"
              :items="portForwardListItem"
              :noItemsView="{ noItems: 'No Item~' }"
              pagination
            >
              <template #uuid="{item}">
                <td class="py-2">
                  <CButtonGroup>
                    <CButton
                      color="primary"
                      square
                      @click="remarkMsg=item.uuid;remarkMsgModal=true;"
                    >
                      Show
                    </CButton>
                  </CButtonGroup>
                </td>
              </template>
              <template #remark="{item}">
                <td class="py-2">
                  <CButtonGroup>
                    <CCardText v-if="item.remark.length <= 30">
                      {{ item.remark }}
                    </CCardText>
                    <CButton
                      color="primary"
                      square
                      v-if="item.remark.length > 30"
                      @click="remarkMsg=item.remark;remarkMsgModal=true;"
                    >
                      Show
                    </CButton>
                  </CButtonGroup>
                </td>
              </template>
              <template #action="{item}">
                <td class="py-2">
                  <CButtonGroup>
                    <CButton
                      color="primary"
                      square
                      disabled
                    >
                      Edit
                    </CButton>
                    <CButton
                      color="danger"
                      square
                      @click="deletePortForward(item.id)"
                    >
                      Delete
                    </CButton>

                  </CButtonGroup>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Request Message"
      color="primary"
      :show.sync="requestMsgModal"
    >
      {{ requestMsg }}
      <template #footer>
        <CButton @click="requestMsgModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
    <CModal
      title="Remark"
      color="primary"
      :show.sync="remarkMsgModal"
    >
      {{ remarkMsg }}
      <template #footer>
        <CButton @click="remarkMsgModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "List",
  data() {
    const portForwardListFields = [
      { key: "id", label: "ID" },
      { key: "router", label: "Router" },
      { key: "router_interface", label: "Outside Interface" },
      { key: "port", label: "Outside Port" },
      { key: "protocol", label: "Protocol" },
      { key: "to_host", label: "Target Host" },
      { key: "to_port", label: "Target Port" },
      { key: "uuid", label: "UUID" },
      { key: "remark", label: "Remark" },
      {
        key: "action",
        label: "Action",
        sorter: false,
        filter: false,
        _style: "width: 10%"
      }
    ];
    const protocolList = [
      { label: "Select a protocol" },
      { value: "tcp", label: "TCP" },
      { value: "udp", label: "UDP" }
    ];
    return {
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      portForwardListFields,
      portForwardListItem: [],
      router: null,
      isPortRange: false,
      interface: null,
      port: null,
      portEnd: null,
      protocolList,
      protocol: null,
      toHost: null,
      toPort: null,
      remark: null,
      routerListItem: [],
      interfaceListItem: [{ label: "Select a Interface" }],
      requestMsgModal: false,
      requestMsg: null,
      remarkMsgModal: false,
      remarkMsg: null
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/port-forward", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.portForwardListItem = response.data.portForwardList.map(item => {
              let router = item.router.name + "-" + item.router.host;
              let router_interface = item.router_interface.type + ":" + item.router_interface.interface_name;
              let port;
              let to_port;
              if (item.is_port_range) {
                port = item.port + "-" + item.port_end;
                to_port = item.port + "-" + item.port_end;
              } else {
                port = item.port;
                to_port = item.to_port;
              }
              return {
                ...item,
                router,
                router_interface,
                port,
                to_port
              };
            });
          }
        });

      if (this.routerListItem.length === 0) {
        this.routerListItem = [{ label: "Select a router" }];
        this.$axios.get("user/router", {
          params: {
            only_dnat_router: "yes"
          }
        })
          .then((response) => {
            if (response.data.code === 200) {
              this.routerListItem.push({ label: "Your Owned Router", disabled: true });
              this.routerList = response.data.userRouter.map(item => {
                let label = item.name + "(" + item.host + ")";
                this.routerListItem.push({ value: item.id, label: label });
              });
              this.routerListItem.push({ label: "Other Authed Router", disabled: true });
              this.routerList = response.data.authedRouter.map(item => {
                let label = "AuthForm:" + item.router.owner.username + "-" + item.router.name + "(" + item.router.host + ")";
                this.routerListItem.push({ value: item.router.id, label: label });
              });
            }
          });
      }
    },
    addPortForward: function() {
      this.$axios.post("user/port-forward/add", {
        router_id: this.router,
        interface_id: this.interface,
        protocol: this.protocol,
        is_port_range: this.isPortRange,
        dst_port: this.port,
        port_end: this.portEnd,
        to_host: this.toHost,
        to_port: this.toPort,
        remark: this.remark
      })
        .then((response) => {
          if (response.data.code === 200) {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
            this.onLoadPage();
          } else if (typeof response.data.code !== "undefined") {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
          }
        });
    },
    getRouterInterface: function() {
      if (this.router !== null) {
        this.interface = null;
        this.interfaceListItem = [{ label: "Select a Interface" }];
        this.$axios.get("user/router/" + this.router + "/interfaces", {
          params: {
            only_dnat_port: 1
          }
        })
          .then((response) => {
            if (response.data.code === 200) {
              this.interfaceList = response.data.interfaces.map(item => {
                let label = item.interface_name + "(" + item.type + ")";
                this.interfaceListItem.push({ value: item.id, label: label });
              });
            }
          });
      }
    },
    syncRouterInterface: function() {
      if (this.router !== null) {
        this.$axios.get("user/router/" + this.router + "/interfaces/sync", {})
          .then((response) => {
            if (response.data.code === 200) {
              this.getRouterInterface();
              this.requestMsg = "Sync Success";
              this.requestMsgModal = true;
            } else {
              this.requestMsg = "Sync Error";
              this.requestMsgModal = true;
            }
          });
      } else {
        this.requestMsg = "Select a router first!";
        this.requestMsgModal = true;
      }
    },
    deletePortForward: function(recordId) {
      this.$axios.delete("user/port-forward/" + recordId + "/delete", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
            this.onLoadPage();
          } else if (typeof response.data.code !== "undefined") {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
          }
        });
    }
  }
};
</script>